import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormatDate, Translate } from '../../../../../utils'
import { groupeActions, testActions } from '../../../../../actions'
import { BtnClick, TableRows, Loader } from '../../../../_Common'
import { ImSearch } from 'react-icons/im'
import { testsConstants } from '../../../../../constants'
class ConfirmPresence extends Component {
    constructor(props) {
        super(props)
        this.state = {
            invits: []
        }
        props.getCandidateGroupe({ groupe: props.dataGroupe.groupe_candidate_id })
        this.handleNext = this.handleNext.bind(this)
        this.handleSearchCandi = this.handleSearchCandi.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        if ((nextProps.getingInvts === 1 && this.props.getingInvts === 0) || (nextProps.asyncPresence === 0 && this.props.asyncPresence === 1)) {
            this.setState({
                invits: nextProps.dataInvits
            })
        }
    }
    confirmCandidate(id_invitation) {
        this.props.confirmCandidate({ invit: id_invitation })
    }
    cancelCandidate(id_invitation) {
        this.props.cancelConfirmation({ invit: id_invitation })
    }
    handleNext() {
        this.props.StoreStep(3, testsConstants.GO_TO_STEP)
    }
    handleSearchCandi(e) {
        const { name, value } = e.target
        let dataInvits = this.props.dataInvits
        if (value.length > 2) {
            dataInvits = dataInvits.filter(elem => (elem.user.lastname.toLowerCase().includes(value.toLowerCase()) || elem.user.firstname.toLowerCase().includes(value.toLowerCase())))
        }
        this.setState({
            [name]: value,
            invits: dataInvits
        })
    }
    getStatusColor = (statusInv) =>{
        switch(statusInv){
            case 'joined':
                return 'accpt_clr'
            case 'unknown':
                return 'color-org'
            case 'refused':
                return 'txt-dng'
            default:
                return 'pnding_clr'
        }
    }
    render() {
        const { getingInvts, asyncPresence, absence, presence } = this.props
        return (
            <>
                <div className="dis-flex item-center brd-equ rad-5 padding-def sideSmbdg5555">
                    <ImSearch className="icon_color mr-2" className="curs-pointer" />
                    <input type="text" autoComplete="off" style={{ width: "350px" }} name="search" onChange={this.handleSearchCandi} placeholder="Rechercher un candidat" value={this.state.search} className="sizeF-1em leftM-5" />
                </div>
                <div className="leftM-auto righttM-auto" style={{ width: "90%", height: 'calc(100% - 186px)' }}>
                    {(getingInvts === 0 || asyncPresence === 0) && <Loader />}
                    <TableRows
                        classSticky="fix_elem_top"
                        styleTop={{ height: "calc(100% - 48px)" }}
                        columns={[
                            { name: Translate('fields', 'lastname'), rowKey: "user", render: user => user.lastname },
                            { name: Translate('fields', 'firstname'), rowKey: "user", render: user => user.firstname },
                            { name: Translate('fields', 'phone'), rowKey: "user", render: user => user.phone },
                            { name: Translate('fields', 'email'), rowKey: "user", render: user => user.email },
                            { name: Translate('candidate', 'civility'), rowKey: "candidate", render: candidate => candidate.civility },
                            { name: Translate('candidate', 'age'), rowKey: "candidate", render: candidate => candidate.age },
                            { name: Translate('candidate', 'fitgeneral'), rowKey: "candidate", render: candidate => candidate.fitgenerale },
                            { name: Translate('candidate', 'codepostale'), rowKey: "candidate", render: candidate => candidate.codepostale },
                            { name: Translate('candidate', 'statusInvit'), rowKey: "status_invitation", render: status_invitation => <strong className={this.getStatusColor(status_invitation)}>{Translate('status', status_invitation)}</strong> },
                            { name: "Présence", rowKey: "is_present", rowParams: "invitation_candidate_id", render: (is_present, invitation_candidate_id) => is_present ? <BtnClick onClick={() => this.cancelCandidate(invitation_candidate_id)} TxtBtn="Annuler" Color="bdg-dng clr-white leftM-8" /> : <BtnClick onClick={() => this.confirmCandidate(invitation_candidate_id)} TxtBtn="Confirmer" Color="bdg_valid clr-white leftM-8" /> }
                        ]}
                        data={this.state.invits}
                        key_table="invitation_candidate_id"
                    />
                    <div className="dis-flex content-btwn item-center" style={{ marginTop: "7px" }}>
                        <div className="dis-flex">
                            <div className="rightM-15"><strong className="accpt_clr sizeF-11em">Nbr Présence :</strong><span> {presence}</span></div>
                            <div><strong className="txt-dng sizeF-11em">Nbr Absence :</strong><span> {absence}</span></div>
                        </div>
                        <BtnClick
                            onClick={this.handleNext}
                            TxtBtn="Passer à l'affectation"
                            Color="bdg-onrg clr-white leftM-8"
                        />
                    </div>
                </div>
            </>
        )
    }
}
function mapState(state) {
    const { dataInvits, getingInvts, asyncPresence, presence, absence } = state.groupe
    const { dataGroupe } = state.tests
    return { dataInvits, getingInvts, asyncPresence, dataGroupe, presence, absence }
}
const Actions = {
    getCandidateGroupe: groupeActions.getCandidateGroupe,
    confirmCandidate: groupeActions.confirmCandidate,
    cancelConfirmation: groupeActions.cancelConfirmation,
    StoreStep: testActions.StoreStep
}
const ConnectedClass = connect(mapState, Actions)(ConfirmPresence)
export { ConnectedClass as ConfirmPresence }
