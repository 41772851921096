import axios from 'axios';
import Cookies from 'js-cookie'
const authUser = Cookies.get('auth_user')
const URL_ROOT = process.env.REACT_APP_URL_APP;
export const RequestService = {
    GetRequest,
    PostRequest,
    PatchRequest,
    DeleteRequest,
    PutRequest
};

function GetRequest(url, protect, params,configs) {
    return axios.get(`${URL_ROOT}${url}`, {
        ...configs,
        headers: protect ? { "Authorization": "Bearer " + authUser } : {},
        params: params
    })
}

function PostRequest(url, data, protect) {
    console.log(' 🚀  => authUser:', authUser)
    return axios.post(`${URL_ROOT}${url}`, data, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}
function PatchRequest(url, data, protect) {
    return axios.patch(`${URL_ROOT}${url}`, data, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}
function DeleteRequest(url, protect) {
    return axios.delete(`${URL_ROOT}${url}`, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}
function PutRequest(url, data, protect) {
    return axios.put(`${URL_ROOT}${url}`, data, {
        headers: protect ? { "Authorization": "Bearer " + authUser } : {}
    })
}