import React,{useEffect,useState} from 'react'
import {Link} from 'react-router-dom'
import {InlineElemnt} from '../../_Common'
export const CandidateList=({candidates=[],notifcationchannelsone,oncllick,setqctivechannel}) => {
    const [activeUser,setActiveUser]=useState(window.activeChannel)

    const setnotfication=(channelid) => {
        var noti=null;
        notifcationchannelsone.forEach(notiget => {
            if(notiget.message_channel_id===channelid) {
                noti=notiget.message_channel_id_count
            }
        })
        return noti
    }
    useEffect(() => {
        setActiveUser(window.activeChannel);
        setqctivechannel(window.activeChannel)
    },[window.activeChannel])
    return (
        <>
            {Array.isArray(candidates)&&candidates.map(({message_channel_id,message_channel,user: {picture_path,full_name}={},last_message: {message_text,read_state}={}},key) => {
                return <Link key={key} to={`/candidate/chat/${message_channel}`} onClick={() => oncllick()}>
                    <InlineElemnt
                        className={`onHvr curs-pointer py-7 px-10 mb-5 box-border pos-relative width-100 ${activeUser===message_channel? "sideSmbdg":""}`}
                        first={
                            <>
                                {setnotfication(message_channel_id)!==0&&setnotfication(message_channel_id)!==null&&
                                    <span className='notificationcinside'>{setnotfication(message_channel_id)}</span>
                                }
                                <img src={picture_path? picture_path:"/assets/img/default.png"} 
                                onError={e => { e.currentTarget.src = "/assets/img/default.png"; }}
                                alt='profile pic' className="width-100 height-100" 
                                style={{borderRadius: '100%',border:"1px solid #cdc6c62b"}} />
                            </>
                        }
                        classFirst="image_board"
                        secend={
                            <div className="dis-flex flex-direction width-100" style={{marginLeft:'5px'}}>
                                <strong style={{marginBottom: 3}} className="sizeF-9em text-truncates">
                                    <span>{full_name}</span>
                                </strong>
                                <span className={`sizeF-9em text-truncate ${read_state? 'font-700 color-org':'clr-grey'}`}>{message_text||'...'}</span>
                            </div>
                        }
                        styleSecend={{width: "calc(100% - 70px)"}}
                    />
                </Link>
            })}
        </>

    )
}