import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormatDate, Translate } from '../../../utils'
import { ContainerPages, NavTab, ButtonCLick, PopTab, InputForm, BtnClick, Loader, RefreshSvg } from '../../_Common'
import { candidateActions, alertActions, groupeActions } from '../../../actions'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { ImSearch } from 'react-icons/im'
import { NoGroupeCanddidate } from './NoGroupeCanddidate'
import { GroupePage } from './GroupePage'
// import { DetailGroupe } from './DetailGroupe'
import { DetailGroupeG } from './DetailGroupeG'
import { EditGroupe } from './EditGroupe'
const urlPropsQueryConfig = {
    currentTab: { type: UrlQueryParamTypes.string },
    tab: { type: UrlQueryParamTypes.string },
    groupe: { type: UrlQueryParamTypes.string },
    editgroupe: { type: UrlQueryParamTypes.string }
};
class GroupeCandidate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showBtn: false,
            candidates: [],
            allSelect: false,
            search: "",
            filterGroupe: {
                sorter: null,
                sort: null
            },
            alertAbandon:false
        }
        this.groupe_name = ""
        this.meet_link = false
        this.test_date = null
        this.start_time = null
        this.end_time = null
        this.fin_date = ""
        this.candidates = []
        props.getCandidateNoGrouped()
        if (props.getingGroupe !== "1") {
            props.getGroupe()
        }
    }
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'candidate')
    }
    componentWillReceiveProps(nextProps) {
        const { addingGroupe, dataAddGroupe, onChangeTab, getGroupe, editingGrp, getingCandNGRP, dataCandNGRP, dataEditGrp, verifingGrp,verifingGrphoto, dataVerifi,abandoningCandi,dataAbandonCandi } = nextProps
        if (this.props.addingGroupe === "0" && addingGroupe === "1" && dataAddGroupe) {
            this.props.SendAlert('Opération réussie', "success")
            this.state.candidates = []
            this.candidates = []
            nextProps.getCandidateNoGrouped(this.state.filterGroupe)
            getGroupe()
            onChangeTab(undefined)
        }
        if (this.props.abandoningCandi === 0 && abandoningCandi === 1 && dataAbandonCandi) {
            this.props.SendAlert('Opération réussie', "success")
            this.state.candidates = []
            this.candidates = []
            nextProps.getCandidateNoGrouped(this.state.filterGroupe)
            this.state.alertAbandon=false
        }
        if (this.props.editingGrp === "0" && editingGrp === "1" && dataEditGrp && dataEditGrp.status === "done") {
            this.props.SendAlert('Opération réussie', "success")
            this.state.candidates = []
            this.candidates = []
            nextProps.getCandidateNoGrouped(this.state.filterGroupe)
            getGroupe()
            this.props.onChangeGroupe(undefined)
            this.props.onChangeEditgroupe(undefined)
        }
        if (this.props.verifingGrp === "0" && verifingGrp === "1" && dataVerifi && dataVerifi.status === "done") {
            this.props.SendAlert('Opération réussie', "success")
        }
        if (this.props.getingCandNGRP === 0 && getingCandNGRP === 1 && dataCandNGRP && Array.isArray(dataCandNGRP.candidates)) {
            this.candidates = dataCandNGRP.candidates
        }
        if (dataVerifi && dataVerifi === "error") {
            this.props.SendAlert("L'opération n'a pas fonctionné", "error")
        }
    }
    static defaultProps = {
        currentTab: 'new'
    }
    static propTypes = {
        currentTab: PropTypes.string,
        tab: PropTypes.string,
        groupe: PropTypes.string,
        editgroupe: PropTypes.string,
        /********************** */
        onChangeCurrentTab: PropTypes.func,
        onChangeTab: PropTypes.func,
        onChangeGroupe: PropTypes.func,
        onChangeEditgroupe: PropTypes.func
    };
    handleChange = (selectedRow) => {
        this.setState({
            showBtn: selectedRow.length > 0,
            candidates: selectedRow
        })
    }
    handleInput = (e) => {
        const { name, value } = e.target
        this[name] = value
    }
    handleAddGroupe = () => {
        let groupe = {
            groupename: this.groupe_name,
            date_test_groupe: this.test_date,
            dateText: FormatDate(this.test_date),
            meet_link: this.meet_link,
            candidates: this.state.candidates,
            start_time: this.start_time,
            end_time: this.end_time,
            fin_date: this.fin_date,
            fineText: FormatDate(this.fin_date)
        }
        this.props.addGroupe(groupe)
    }
    handleRefresh = () => {
        this.state.showBtn = false
        this.state.candidates = []
        this.props.getCandidateNoGrouped(this.state.filterGroupe)
        this.props.getGroupe()
    }
    handleDetailGroupe = (idGroupe) => {
        this.props.getGroupeDetail(idGroupe, '?invitation=true')
        this.props.onChangeGroupe(idGroupe)
    }
    handleSearchCandi = (e) => {
        const { value } = e.target
        const { datacandi } = this.props
        if (value && value.length > 1) {
            this.candidates = this.candidates.filter(elem => (elem.firstname + " " + elem.lastname) && (elem.firstname + " " + elem.lastname).toLowerCase().indexOf(value.toLowerCase()) != -1)
        } else {
            this.candidates = datacandi && Array.isArray(datacandi.candidates) ? datacandi.candidates : this.candidates
        }
        this.setState(this.state)
    }
    handleShowTabGrp = () => {
        if (this.state.allSelect) {
            this.state.candidates = this.candidates.map(candi => { return candi.candidate_id })
        }
        this.props.onChangeTab('group')
    }
    closeEditGroupe = () => {
        this.state.candidates = []
        this.props.onChangeEditgroupe(undefined)
    }
    handleEditGroupe = () => {
        let groupe = {
            idGroupe: this.props.groupe,
            candidates: this.state.candidates,
        }
        this.props.editGroupe(groupe)
    }
    relanceMail = () => {
        let groupe = {
            idGroupe: this.props.groupe
        }

        this.props.startVerification(groupe)
    }
    startMailIncertain = () => {
        let groupe = {
            idGroupe: this.props.groupe
        }
        this.props.startMailIncertain(groupe)
    }
    relancePicture = () => {
        let groupe = {
            idGroupe: this.props.groupe
        }
        this.props.resendPicture(groupe)
    }
    handleSortCandidate = (sorter) => {
        let prevfilter = this.state.filterGroupe
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.props.getCandidateNoGrouped(prevfilter)
        this.setState({
            filterGroupe: prevfilter
        })
    }
    SubmitAbandon = () => {
        this.props.abandonCandidate({candidates: this.state.candidates})
    }
    handleConfirmList = (value) => {
        this.setState({
            alertAbandon: value
        })
    }
    removeMember = (id_invit) => {
        this.props.sortCandidate({invitation:id_invit})
    }
    render() {
        const { getingCandNGRP, getingGroupe, dataGroupes, addingGroupe, getDetGroupe, dataDetaGroupe, editingGrp,verifingGrphoto, verifingGrp,abandoningCandi,removingMember } = this.props
        return (
            <ContainerPages titlePage={Translate('candidate', 'repartaugroupe')} contentTop={
                this.props.currentTab === "new" &&
                <div className="dis-flex item-center">
                    <ImSearch className="icon_color mr-2" />
                    <input type="text" autoComplete="off" style={{ width: "250px" }} name="search" onChange={this.handleSearchCandi} placeholder="Rechercher un candidat" className="sizeF-1em " />
                </div>
            } contentLeft={<div className="dis-flex">
                <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                {this.state.showBtn && <> 
                    <ButtonCLick action={()=> this.handleConfirmList(true)} TextBottun={Translate('candidate', 'abandon')} /> 
                    <ButtonCLick action={this.handleShowTabGrp} TextBottun={Translate('candidate', 'addGroupe')} /> 
                </>}
            </div>} StyleChildren={{ height: "calc(100% - 60px)" }}>
                {getingCandNGRP === 0 && <Loader style={{ minHeight: "250px" }} />}
                <>
                    <div className="dis-flex item-center content-btwn">
                        <NavTab
                            ElemeList={[{ text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab('new')}>{Translate('candidate', 'candidateNogrouper')}</div>, active: this.props.currentTab === "new" }, { text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab('toComplete')}>Groupe à compléter</div>, active: this.props.currentTab === "toComplete" }, { text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab('grouped')}>{Translate('candidate', 'groupeCandida')}</div>, active: this.props.currentTab === "grouped" }, { text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab('complet')}>{Translate('candidate', 'cdvalider')}</div>, active: this.props.currentTab === "complet" }]}
                        />
                    </div>
                    <hr className="brd-none paddTB-3" style={{ borderBottom: "1px solid #e1dfdd" }} />
                </>
                {this.props.currentTab === "new" && <>
                    <NoGroupeCanddidate onChange={this.handleChange} selectedCandi={this.state.candidates} allselect={this.state.allSelect} dataGroupe={this.candidates} handleSorter={this.handleSortCandidate} />
                    <PopTab isActive={this.props.tab === "group"} TabTitle={Translate('candidate', 'addGroupe')} close={() => this.props.onChangeTab(undefined)} width="40%" height="70%">
                        {addingGroupe === "0" && <Loader />}
                        <div className="dis-flex  flex-direction item-center content-between height-100">
                            <span className="dis-block width-100">{Translate('candidate', 'vstocreateg') + " "}(<strong>{this.state.candidates.length}</strong>) candidat(e)s</span>
                            <InputForm placeholder="Nom du groupe" name="groupe_name" onChange={this.handleInput} />
                            <div className="width-100">
                                <h4 style={{ margin: "10px 0px" }}>Planning de l'invitation au JTA</h4>
                                <div className="dis-flex topM-15">
                                    <div className="width-100 rightM-8">
                                        <label>Date JTA</label>
                                        <InputForm placeholder="Date de test" type="date" name="test_date" onChange={this.handleInput} />
                                    </div>
                                    <div className="width-100">
                                        <label>Date limite d'invitation</label>
                                        <InputForm placeholder="Date limite d'invitation" type="date" name="fin_date" onChange={this.handleInput} />
                                    </div>
                                </div>
                                <div className="dis-flex topM-15">
                                    <div className="width-100 rightM-8">
                                        <label>Heure début</label>
                                        <InputForm placeholder="Heure début" type="time" name="start_time" onChange={this.handleInput} />
                                    </div>
                                    <div className="width-100">
                                        <label>Heure fin</label>
                                        <InputForm placeholder="Heure fin" type="time" name="end_time" onChange={this.handleInput} />
                                    </div>
                                </div>
                            </div>
                            <div className="width-100">
                                <h4 style={{ margin: "10px 0px" }}>Journée Test d'Affinité en distanciel</h4>
                                <InputForm placeholder="Lien de meet pour JTA en distanciel" type="text" name="meet_link" onChange={this.handleInput} />
                            </div>
                            <BtnClick
                                onClick={this.handleAddGroupe}
                                Color="width-100 bdg-onrg clr-white"
                                style={{ maxWidth: "100px" }}
                                TxtBtn={Translate('candidate', 'add')}
                            />
                        </div>
                    </PopTab>
                </>}
                {(this.props.currentTab === "grouped" || this.props.currentTab === "complet" || this.props.currentTab === "toComplete") && <>
                    {getingGroupe === "0" && <Loader />}
                    <GroupePage dataGroupe={dataGroupes} currentShow={this.props.currentTab === "grouped" ? "inProgres" : this.props.currentTab === "toComplete" ? "new" : "complet"} action={this.handleDetailGroupe} />
                    {this.props.groupe && <DetailGroupeG startMail={this.relanceMail} startMailIncertain={this.startMailIncertain} relancePicture={this.relancePicture} addMembers={() => this.props.onChangeEditgroupe("edit")} removeMember={this.removeMember} showData={getDetGroupe==="1"} loading={getDetGroupe==="0" || removingMember===0} loaderAction={verifingGrp === "0"} dataGroupe={dataDetaGroupe} close={this.props.onChangeGroupe} />}
                </>}
                {(this.props.currentTab === "toComplete" && this.props.editgroupe === "edit") &&
                    <PopTab isActive={this.props.editgroupe === "edit"} TabTitle={Translate('candidate', 'addGroupe')} close={this.closeEditGroupe} width="93%" height="88%">
                        {editingGrp === "0" && <Loader />}
                        <EditGroupe onChange={this.handleChange} selectedCandi={this.state.candidates} onCLose={this.closeEditGroupe} allselect={this.state.allSelect} dataGroupe={this.candidates} handleEdit={this.handleEditGroupe} />
                    </PopTab>}
                    {this.state.alertAbandon && <PopTab isActive={this.state.alertAbandon} TabTitle={Translate('candidate', 'abandon')} close={()=> this.handleConfirmList(false)} width="470px">
                    {abandoningCandi === 0 && <Loader />}
                    <p>Vous êtes sur le point de mettre les {this.state.candidates.length} dans la liste des abandonnés . Cette action est irréversible !</p>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnClick
                            onClick={()=> this.handleConfirmList(false)}
                            TxtBtn="Annuler"
                            style={{ width: "45%" }}
                            Color="leRgM-15 bdg-onrg-op color-org"
                        />
                        <BtnClick
                            onClick={this.SubmitAbandon}
                            style={{ width: "45%" }}
                            TxtBtn="Confirmer"
                            Color="leRgM-15 width-100 bdg-dng clr-white"
                        />
                    </div>
                </PopTab>}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { addingGroupe, dataAddGroupe, getingGroupe, dataGroupes, getDetGroupe, dataDetaGroupe, editingGrp, dataEditGrp,verifingGrphoto, verifingGrp, dataVerifi,abandoningCandi,dataAbandonCandi,removingMember } = state.candidate
    const { getingCandNGRP, dataCandNGRP } = state.groupe
    return { addingGroupe, dataAddGroupe, getingGroupe, dataGroupes, getDetGroupe, dataDetaGroupe, editingGrp, dataEditGrp,verifingGrphoto, verifingGrp, dataVerifi, getingCandNGRP, dataCandNGRP ,abandoningCandi,dataAbandonCandi,removingMember}
}
const actionsClass = {
    getCandidateNoGrouped: groupeActions.getCandidateNoGrouped,
    addGroupe: candidateActions.addGroupe,
    getGroupe: candidateActions.getGroupe,
    editGroupe: candidateActions.editGroupe,
    SendAlert: alertActions.SendAlert,
    getGroupeDetail: candidateActions.getGroupeDetail,
    startVerification: candidateActions.startVerification,
    startMailIncertain: candidateActions.startMailIncertain,
    abandonCandidate: candidateActions.abandonCandidate,
    sortCandidate:candidateActions.sortCandidate,
    resendPicture:candidateActions.resendPicture,
}
const connectedClass = connect(mapState, actionsClass)(GroupeCandidate);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedClass)
export { QueryProps as GroupeCandidate };