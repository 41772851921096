import {candidateConstants} from '../constants';
import {RequestService,FunctionService} from '../services';
export const candidateActions={
    getCandidate,
    addGroupe,
    getGroupe,
    getGroupeDetail,
    importCandidates,
    confirmCandidate,
    removeGroupe,
    getGallery,
    editGroupe,
    startVerification,
    startMailIncertain,
    resendPicture,
    abandonCandidate,
    sortCandidate
};
function sortCandidate(data) {
    
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.REMOVE_CANDIDATE_GROUPE_REQUEST))
        RequestService.PostRequest('group/candidate/remove',data,true)
        .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.REMOVE_CANDIDATE_GROUPE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.REMOVE_CANDIDATE_GROUPE_FAILURE))
            })
    }
}
function getCandidate(filter) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.GET_CANDIDATE_REQUEST))
        RequestService.GetRequest('candidate/all',true,filter)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.GET_CANDIDATE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.GET_CANDIDATE_FAILURE))
            })
    }
}
function getGallery(filter) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.GET_GALLERY_REQUEST))
        RequestService.GetRequest('candidate/gallery'+(filter? filter:""),true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.GET_GALLERY_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.GET_GALLERY_FAILURE))
            })
    }
}
function removeGroupe(candidate) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.DELETE_GROUPE_CANDIDATE_REQUEST))
        RequestService.PostRequest('group/delete/member',candidate,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.DELETE_GROUPE_CANDIDATE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.DELETE_GROUPE_CANDIDATE_FAILURE))
            })
    }
}
function addGroupe(groupe) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.ADD_GROUPE_REQUEST))
        RequestService.PostRequest('group/create',groupe,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.ADD_GROUPE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.ADD_GROUPE_FAILURE))
            })
    }
}

function abandonCandidate(candidates) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.ABANDON_CANDIDATE_REQUEST))
        RequestService.PostRequest('candidate/abandon',candidates,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.ABANDON_CANDIDATE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.ABANDON_CANDIDATE_FAILURE))
            })
    }
}

function editGroupe(groupeInfo) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.EDIT_GROUPE_REQUEST))
        RequestService.PostRequest('group/add/member',groupeInfo,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.EDIT_GROUPE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.EDIT_GROUPE_FAILURE))
            })
    }
}
function getGroupe() {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.GET_GROUPE_REQUEST))
        RequestService.GetRequest('group/all',true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.GET_GROUPE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.GET_GROUPE_FAILURE))
            })
    }
}
function getGroupeDetail(idgroupe,filter) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.GET_DETAIL_GROUPE_REQUEST))
        RequestService.GetRequest('group/detail/'+idgroupe+(filter? filter:""),true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.GET_DETAIL_GROUPE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.GET_DETAIL_GROUPE_FAILURE))
            })
    }
}
function importCandidates(list) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.IMPORT_CANDIDATE_REQUEST))
        RequestService.PostRequest('candidate/create',list,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.IMPORT_CANDIDATE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.IMPORT_CANDIDATE_FAILURE))
            })
    }
}
function confirmCandidate(candidate) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.CONFIRM_CANDIDATE_REQUEST))
        RequestService.PostRequest('group/confirm/member',candidate,true)
            .then(res => {
                dispatch(FunctionService.succes({response: res.data,initData: candidate},candidateConstants.CONFIRM_CANDIDATE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.CONFIRM_CANDIDATE_FAILURE))
            })
    }
}
function startVerification(groupe) {
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.VERIFICATION_GROUPE_REQUEST))
        RequestService.PostRequest('group/verification',groupe,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.VERIFICATION_GROUPE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,candidateConstants.VERIFICATION_GROUPE_FAILURE))
            })
    }
}
function startMailIncertain(groupe) {
    console.log(' startMailIncertain ')
    return dispatch => {
        dispatch(FunctionService.request(candidateConstants.RESEND_INCERTION_GROUPE_REQUEST))
        RequestService.PostRequest('group/startmailincertain',groupe,true)
            .then(res => {
                // dispatch(FunctionService.succes(res.data,candidateConstants.RESEND_PICTURE_GROUPE_SUCCESS))
                dispatch(FunctionService.succes(res.data,candidateConstants.RESEND_INCERTION_GROUPE_SUCCESS))
            })
            .catch(err => {
                // dispatch(FunctionService.failure(err.response,candidateConstants.RESEND_PICTURE_GROUPE_FAILURE))
                dispatch(FunctionService.failure(err.response,candidateConstants.RESEND_INCERTION_GROUPE_FAILURE))
            })
    }
}

function resendPicture(groupe) {
    return dispatch => {
        console.log(' resendPicture 99 ')
        dispatch(FunctionService.request(candidateConstants.RESEND_PICTURE_GROUPE_REQUEST))
        RequestService.PostRequest('group/picture/resend',groupe,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,candidateConstants.RESEND_PICTURE_GROUPE_SUCCESS))
            })
            .catch(err => {
                // alert('ffffff')
                dispatch(FunctionService.failure(err.response,candidateConstants.RESEND_PICTURE_GROUPE_FAILURE))
            })
    }
}
