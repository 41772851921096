import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { evaluTestActions,exportsActions } from '../../../../actions'
import { InlineElemnt, PopTab, Loader, DownArrowSvg, MissingSvg,BtnClick } from '../../../_Common'
import { Qestionview, ViewerEvaluation } from './utils'
class DetailAnswerJta extends Component{
    constructor(props){
        super(props)
        this.state={
            viewLabel: true,
            viewEvalu: false,
        }
        props.detailAswerJta(props.detailAnswer)
    }
    handleToggle = (name) => {
        let currentState = this.state[name]
        this.setState({
            [name]: !currentState
        })
    }
    exportPdf = (typeUser) =>{
        if(typeUser==="candidate"){
            const getLink=exportsActions.ExportCandidateAnswer(this.props.detailAnswer)
            toast.promise(
                getLink,
                {
                pending: "L'exportation est en cours de traitement",
                success: "Votre fichier est prêt à utiliser",
                error: "Une erreur s'est produite lors de l'exportation"
                }
            )
            getLink
                .then(res=>{
                    if(res && res.data && res.data.status==="done"){
                        window.open(res.data.link)
                    }
                })
                .catch(err=>{

                })
        }else{
            const getLink=exportsActions.ExportEvaluatorAnswer(this.props.detailAnswer)
            toast.promise(
                getLink,
                {
                pending: "L'exportation est en cours de traitement",
                success: "Votre fichier est prêt à utiliser",
                error: "Une erreur s'est produite lors de l'exportation"
                }
            )
            getLink
                .then(res=>{
                    if(res && res.data && res.data.status==="done"){
                        window.open(res.data.link)
                    }
                })
                .catch(err=>{

                })
        }
    }
    render(){
        const { getingAnswers, dataAnswerJta } = this.props
        const {isAbsent:isPresent,isEvaluate:notEvalua,tablete:{color_code="#2d70f1",color=""}={},candidate:{firstname="...",lastname=""}={},evaluateur:{firstname:EvalName1="...",lastname:EvalName2=""}={},test:{questions:testConai=[],titlegrid:titleGrileTest="",noteCandi:{note="N",nb_qestions="A"}={}}={},evalu:{titlegrid:titleGrileEval="",sections:testEvalu=[],score_global="",score_moyenne=""}={}}=dataAnswerJta || {}
        let renderResult=false
        if (getingAnswers === "1" && dataAnswerJta && dataAnswerJta.status==="done") {
            renderResult=true
        }
        return(
            <PopTab isActive={true} TabTitle={`Résultat de JTA pour : ${firstname} ${lastname}`} close={this.props.handleClose} width="93%" height="93%">
                {getingAnswers === "0" && <Loader />}
                {renderResult ?
                <div className="OvfAtoY height-100" style={{paddingRight:20}}>
                    <div className={"dis-flex sm-padding curs-pointer " + this.props.bdg_st} onClick={() => this.handleToggle('viewLabel')}>
                        <InlineElemnt
                            className="font-600 curs-pointer content-btwn bdg-onrg-op width-100 rad-5"
                            style={{padding:"5px 13px",border:"2px solid #2d70f1"}}
                            classSecend="sizeF-9em leftM-5"
                            secend={<span className="dis-flex hasRotat" data-rotate={this.state.viewLabel ? 'up' : 'down'}>{DownArrowSvg}</span>}
                            first={<strong className="sizeF-11em">Résultat de test de conaissance</strong>}
                        />
                    </div>
                    {isPresent ?
                        <div className="dis-flex flex-direction content-center item-center width-100" style={{ padding: "15px 0" }}>
                            {MissingSvg}
                            <h2>Accune réponse</h2>
                        </div>
                        :
                        <div className="dis-flex flx-warp width-100 ToggleCntn OvfAtoY" toggle={this.state.viewLabel ? 'up' : 'down'}>
                            <div className="pd-14-20 width-100" style={{paddingBottom:0}}>
                                <h3 className="" style={{ marginTop: "0px" }}>
                                    {titleGrileTest}
                                </h3>
                                {testConai.map((qst, key) => {
                                    return <Qestionview key={key} index={key + 1} qst={qst} />
                                })}
                                <hr className="my-10"/>
                                <div className="pos-sticky bottom-null bdg-white dis-flex width-mxcn leftM-auto" style={{zIndex:2,paddingTop:5}}>
                                    <strong style={{border:"2px solid var(--valdsccCm)",padding:"5px 12px"}} className="color-org margin-null sizeF-11em bdg-valid rad-5 rightM-8">{`Note : ${note} / ${nb_qestions}`}</strong>
                                    <BtnClick onClick={()=>this.exportPdf('candidate')} TxtBtn="Exporter PDF" Color="bgd-pdf clr-white select-non" />
                                </div>
                            </div>
                        </div>}
                    <hr />
                    <div className={"dis-flex sm-padding curs-pointer " + this.props.bdg_st} onClick={() => this.handleToggle('viewEvalu')}>
                        <InlineElemnt
                            className="font-600 curs-pointer content-btwn width-100 rad-5"
                            style={{padding:"5px 13px",background:color_code+"20",border:"2px solid "+color_code}}
                            classSecend="sizeF-9em leftM-5"
                            secend={<span className="dis-flex hasRotat" data-rotate={this.state.viewEvalu ? 'up' : 'down'}>{DownArrowSvg}</span>}
                            first={<strong className="sizeF-11em"><span className="clr-grey">Résultat d'évaluation de</span> l'évaluateur : {`${EvalName1} ${EvalName2}`} / sur Tablette : {color}</strong>}
                        />
                    </div>
                    {notEvalua ?
                        <div className="dis-flex flex-direction content-center item-center width-100" style={{ padding: "15px 0" }}>
                            {MissingSvg}
                            <h2>Non évalué</h2>
                        </div>
                        :
                        <div className="dis-flex flx-warp width-100 ToggleCntn OvfAtoY" toggle={this.state.viewEvalu ? 'up' : 'down'}>
                            <div className="pd-14-20 width-100">
                                <h3 style={{ marginTop: "0px" }}>{titleGrileEval}</h3>
                                <ViewerEvaluation dataSection={testEvalu} />
                                <hr className="my-10"/>
                                <div className="pos-sticky bottom-null bdg-white dis-flex width-mxcn leftM-auto" style={{zIndex:2,paddingTop:5}}>
                                    <strong style={{border:"2px solid var(--valdsccCm)",padding:"5px 12px"}} className="color-org margin-null sizeF-11em bdg-valid rad-5 rightM-8">{`Moyenne Score séq : ${score_moyenne} / ${score_global}`}</strong>
                                    <BtnClick onClick={()=>this.exportPdf('evaluator')} TxtBtn="Exporter PDF" Color="bgd-pdf clr-white select-non" />
                                </div>
                            </div>
                        </div>}
                </div>:''}
            </PopTab>
        )
    }
}
function mapState(state) {
    const {  getingAnswers, dataAnswerJta } = state.evaluTest
    return {  getingAnswers, dataAnswerJta }
}
const actionsClass = {
    detailAswerJta: evaluTestActions.detailAswerJta
}
const connectedClass = connect(mapState, actionsClass)(DetailAnswerJta);
export { connectedClass as DetailAnswerJta };