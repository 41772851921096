import {candidateConstants} from '../constants';
export function candidate(state,action) {
    switch(action.type) {
        case candidateConstants.GET_CANDIDATE_REQUEST:
            return {
                ...state,
                getingcandi: "0"
            };
        case candidateConstants.GET_CANDIDATE_SUCCESS:
            return {
                ...state,
                getingcandi: "1",
                datacandi: action.payload
            };
        case candidateConstants.GET_CANDIDATE_FAILURE:
            return {
                ...state,
                getingcandi: "2",
                datacandi: action.payload
            };
        case candidateConstants.IMPORT_CANDIDATE_REQUEST:
            return {
                ...state,
                importingCand: "0",
                dataImport: action.payload
            };
        case candidateConstants.IMPORT_CANDIDATE_SUCCESS:
            return {
                ...state,
                importingCand: "1",
                dataImport: action.payload
            };
        case candidateConstants.IMPORT_CANDIDATE_FAILURE:
            return {
                ...state,
                importingCand: "2",
                dataImport: action.payload
            };
        case candidateConstants.DELETE_GROUPE_CANDIDATE_REQUEST:
            return {
                ...state,
                deleteingGrpCand: "0"
            };
        case candidateConstants.DELETE_GROUPE_CANDIDATE_SUCCESS:
            return {
                ...state,
                deleteingGrpCand: "1",
                dataGrpDelte: action.payload
            };
        case candidateConstants.DELETE_GROUPE_CANDIDATE_FAILURE:
            return {
                ...state,
                deleteingGrpCand: "2",
                dataGrpDelte: action.payload
            };
        case candidateConstants.GET_GROUPE_REQUEST:
            return {
                ...state,
                getingGroupe: "0"
            };
        case candidateConstants.GET_GROUPE_SUCCESS:
            return {
                ...state,
                getingGroupe: "1",
                dataGroupes: action.payload
            };
        case candidateConstants.GET_GROUPE_FAILURE:
            return {
                ...state,
                getingGroupe: "2",
                dataGroupes: action.payload
            };
            /******************** */
        case candidateConstants.ADD_GROUPE_REQUEST:
            return {
                ...state,
                addingGroupe: "0"
            };
        case candidateConstants.ADD_GROUPE_SUCCESS:
            return {
                addingGroupe: "1",
                dataAddGroupe: action.payload
            };
        case candidateConstants.ADD_GROUPE_FAILURE:
            return {
                ...state,
                addingGroupe: "2",
                dataAddGroupe: action.payload
            };
            /**************************** */

        /******************** */
        case candidateConstants.ABANDON_CANDIDATE_REQUEST:
            return {
                ...state,
                abandoningCandi: 0
            };
        case candidateConstants.ABANDON_CANDIDATE_SUCCESS:
            return {
                abandoningCandi: 1,
                dataAbandonCandi: action.payload
            };
        case candidateConstants.ABANDON_CANDIDATE_FAILURE:
            return {
                ...state,
                abandoningCandi: 2
            };
            /**************************** */
        case candidateConstants.EDIT_GROUPE_REQUEST:
            return {
                ...state,
                editingGrp: "0"
            };
        case candidateConstants.EDIT_GROUPE_SUCCESS:
            return {
                ...state,
                editingGrp: "1",
                dataEditGrp: action.payload
            };
        case candidateConstants.EDIT_GROUPE_FAILURE:
            return {
                ...state,
                editingGrp: "2",
                dataEditGrp: action.payload
            };
        case candidateConstants.VERIFICATION_GROUPE_REQUEST:
            return {
                ...state,
                verifingGrp: "0"
            };
        case candidateConstants.VERIFICATION_GROUPE_SUCCESS:
            return {
                ...state,
                verifingGrp: "1",
                dataVerifi: action.payload
            };
        case candidateConstants.VERIFICATION_GROUPE_FAILURE:
            return {
                ...state,
                verifingGrp: "2",
                dataVerifi: action.payload
            };

        case candidateConstants.RESEND_INCERTION_GROUPE_REQUEST:
            return {
                ...state,
                verifingGrp: "0"
            };
        case candidateConstants.RESEND_INCERTION_GROUPE_SUCCESS:
            return {
                ...state,
                verifingGrp: "1",
                dataVerifi: action.payload
            };
        case candidateConstants.RESEND_INCERTION_GROUPE_FAILURE:
            console.log('erorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr')
            return {
                ...state,
                verifingGrp: "2",
                dataVerifi: action.payload
            };

        case candidateConstants.RESEND_PICTURE_GROUPE_REQUEST:
            console.log('RESEND_PICTURE_GROUPE_REQUEST : ',action)
            return {
                ...state,
                verifingGrphoto: "0",
                verifingGrp: "0"
            };
        case candidateConstants.RESEND_PICTURE_GROUPE_SUCCESS:
            console.log('RESEND_PICTURE_GROUPE_SUCCESS : ',action)
            return {
                ...state,
                verifingGrphoto: "1",
                verifingGrp: "1",
                dataVerifi: action.payload
            };
            case candidateConstants.RESEND_PICTURE_GROUPE_FAILURE:
                console.log('RESEND_PICTURE_GROUPE_FAILURE : ',action)
                return {
                    ...state,
                    verifingGrphoto: "2",
                    verifingGrp: "2",
                dataVerifi: 'error'
            };


        case candidateConstants.GET_DETAIL_GROUPE_REQUEST:
            return {
                ...state,
                getDetGroupe: "0"
            };
        case candidateConstants.GET_DETAIL_GROUPE_SUCCESS:
            return {
                ...state,
                getDetGroupe: "1",
                dataDetaGroupe: action.payload
            };
        case candidateConstants.GET_DETAIL_GROUPE_FAILURE:
            return {
                ...state,
                getDetGroupe: "2",
                dataDetaGroupe: action.payload
            };
        case candidateConstants.GET_GALLERY_REQUEST:
            return {
                ...state,
                getingGallery: "0"
            };
        case candidateConstants.GET_GALLERY_SUCCESS:
            return {
                ...state,
                getingGallery: "1",
                dataGallery: action.payload
            };
        case candidateConstants.GET_GALLERY_FAILURE:
            return {
                ...state,
                getingGallery: "2",
                dataGallery: action.payload
            };
        case candidateConstants.CONFIRM_CANDIDATE_REQUEST:
            return {
                ...state,
                confirmingCandi: "0"
            };
        case candidateConstants.CONFIRM_CANDIDATE_SUCCESS:
            let dataReturn=action.payload
            let dataGroupe=state.dataDetaGroupe
            if(dataReturn&&dataReturn.response&&dataReturn.response.message==="candidate confirmed") {
                let indexCandi=dataGroupe.groupes.invitations.map(candi => {return candi.candidate_id}).indexOf(dataReturn.initData.idCandidate)
                if(indexCandi>=0) {
                    dataGroupe.groupes.invitations[indexCandi].status_invitation="joined"
                }
            }
            return {
                ...state,
                confirmingCandi: "1",
                dataDetaGroupe: dataGroupe,
                datasimple: action.payload
            };
        case candidateConstants.CONFIRM_CANDIDATE_FAILURE:
            return {
                ...state,
                confirmingCandi: "2",
                dataCOnfirmCandi: action.payload
            };
        
        case candidateConstants.REMOVE_CANDIDATE_GROUPE_REQUEST:
            return {
                ...state,
                removingMember: 0
            };
        case candidateConstants.REMOVE_CANDIDATE_GROUPE_SUCCESS:
            return {
                ...state,
                removingMember: 1,
                dataDetaGroupe: action.payload
            };
        case candidateConstants.REMOVE_CANDIDATE_GROUPE_FAILURE:
            return {
                ...state,
                removingMember: 2
            };
        default:
            return {...state}
    }
}