import React from 'react'
export const MessageItem=(props) => {
    const gettimes=(pr) => {
        var date=new Date(pr);
        var year=date.getFullYear();
        var month=date.getMonth()+1;
        var dt=date.getDate();
        var hour=date.getHours();
        var minutes=date.getMinutes();

        if(dt<10) {
            dt='0'+dt;
        }
        if(month<10) {
            month='0'+month;
        }

        return (
            <>
                <p className="timemsg" style={{padding: "0 15px",margin: '0 0 3px 0'}} >{year}-{month}-{dt}
                    <span style={{marginLeft: '5px'}}>{hour}:{minutes}</span>
                </p>
            </>
        );
    }
    return (
        <div className={`dis-flex item-center mb-10 ${props.isMe? "row-revers":""}`}>
            {!props.isMe&&<div className="sm-widhg">
                <img src={props.picture}
                onError={e => { e.currentTarget.src = "/assets/img/default.png"; }} 
                style={{borderRadius: "100%"}} alt='userpicture' className="width-100 height-100" />
            </div>}
            <div style={{maxWidth: '60%'}}>
                <div style={{textAlign: props.isMe? "right":'left'}}>
                    {gettimes(props.datamsg)}
                </div>
                <div className={`${props.isMe? "bdg-onrg-op-3 clr-white":"sideSmbdg"}  rad-8 mx-8`} style={{padding: "6px 12px"}}>
                    <span className="" style={{wordBreak: 'break-word'}}>{props.message}</span>
                    {/* <span className="">{getMinutes(props.datamsg)}</span> */}
                </div>
            </div>
        </div>
    )
}