import React, { Component } from 'react'
import { Translate, FormatDate } from '../../../utils'
import {exportsActions} from '../../../actions/exports.actions'
import { TableRows, BtnActions, ShowSvg ,PDFSvg} from '../../_Common'
class GroupePage extends Component {
    render() {
        return (
            <TableRows
                classSticky="fix_elem_top"
                styleTop={{ height: "calc(100% - 50px)" }}
                columns={[
                    { name: Translate('test', 'groupede'), rowKey: "groupename" },
                    { name: Translate('fields', 'dateCreate'), rowKey: "created_at", render: created_at => FormatDate(created_at, true) },
                    { name: Translate('fields', 'affichgroupe'), rowKey: "groupe_candidate_id", render: groupe_candidate_id => <BtnActions btns={
                        [{ icon: ShowSvg, action: () => this.props.action(groupe_candidate_id) },
                        { icon: PDFSvg, action: () => exportsActions.PresenceFiche({groupe:groupe_candidate_id}) }
                    ]} /> },
                ]}
                data={this.props.dataGroupe && this.props.dataGroupe.groupes[this.props.currentShow]}
                key_table="groupe_candidate_id"
            />

        )
    }
}
export { GroupePage }